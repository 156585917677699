
  import { api } from "@/api";
  import { dispatchAddNotification } from "@/store/main/actions";
  import { readToken } from "@/store/main/getters";
  import { Vue, Component } from "vue-property-decorator";

  @Component
  export default class HostSeminar extends Vue {
    name = "";
    email = "";
    location = "";
    disabled = false;

    get invalid() {
      return !this.name || !this.email || !this.location;
    }

    async onSubmit() {
      const token = readToken(this.$store);

      this.disabled = true;

      try {
        await api.sendHostSeminarRequest(token, {
          name: this.name,
          email: this.email,
          location: this.location,
        });

        dispatchAddNotification(this.$store, {
          content: "Request to host seminar sent!",
          color: "success",
        });
      } catch {
        dispatchAddNotification(this.$store, {
          content: "Failed to send host seminar request",
          color: "error",
        });
      }
    }
  }
